import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  selfServiceRequestSummaries: () => 'self-service/requests/summaries',
  selfServiceRequestById: (id) => `self-service/requests/${id}`,
  saveSelfServiceRequest: () => 'self-service/requests',
  allSelfServiceVendors: () => 'self-service/vendors/all',
  updateSelfServiceRequest: (id) => `self-service/requests/${id}`,
  generateNextPMSelfServiceRequest: (id) =>
    `self-service/requests/${id}/generate-next`,
  getSelfServicePmRequestHistory: (id) =>
    `self-service/requests/${id}/pm-history`,
  selfServiceActivity: () => 'self-service/activities',
  selfServiceActivityById: (id) => `self-service/activities/${id}`,
  selfServiceAttachments: (requestId) =>
    `self-service/attachments/request/${requestId}`,
  selfServiceAttachmentById: (requestId, attachmentId) =>
    `self-service/attachments/${requestId}/attachments/${attachmentId}`,
  selfServiceRequestPrompts: (requestId) =>
    `self-service/requests/${requestId}/prompts`,
  selfServiceRequestFollowUp: (requestId) =>
    `self-service/requests/${requestId}/follow-up`,
  selfServiceTroubleshootingGuidesByAsset: (assetId) =>
    `self-service/troubleshooting/guide/by-asset/${assetId}`,
  selfServiceTroubleshootingGuidesById: (id) =>
    `self-service/troubleshooting/guide/${id}`,
  selfServiceTroubleshootingAttempt: () =>
    'self-service/troubleshooting/attempt',
  selfServiceTroubleshootingAttemptById: (id) =>
    `self-service/troubleshooting/attempt/${id}`,
  assignVendor: (requestId) =>
    `self-service/requests/${requestId}/assign-vendor`,
  setEta: (requestId) => `self-service/requests/${requestId}/set-eta`,
  selfServicePmChecklistItems: () => 'self-service/pm/checklist',
  markServicePmChecklistItem: () => 'self-service/pm/checklist/mark',
  bulkCrudServicePmChecklistItems: () => 'self-service/pm/checklist/edit',
  vendorContractedRate: () => 'vendor-contracted-rates',
  vendorContractedRateById: (id) => `vendor-contracted-rates/${id}`,
  vendorContractedRatesByVendor: (vendorId) =>
    `vendors/${vendorId}/contractedRates`,
  selfServiceRequestAutomations: (requestId) =>
    `self-service/requests/${requestId}/automations`,
};

/**
 * Get a list of self-service requests
 * @param {object} query
 * @returns {Array}
 */
export async function getSelfServiceRequestList(query) {
  try {
    const res = await esApiV3.get(api.selfServiceRequestSummaries(), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get self-service requests',
    );
  }
  return null;
}

/**
 * Get a single request by id
 * @param {string} id
 * @returns {object}
 */
export async function getSelfServiceRequestById(id) {
  try {
    const res = await esApiV3.get(api.selfServiceRequestById(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      `An error occurred while trying to get self-service request by id: ${id}`,
    );
  }
  return null;
}

function toCreateRequestDto(newRequest) {
  return {
    requestType: newRequest.requestType,
    requestPriority: newRequest.priority,
    assetId: newRequest.asset.id,
    incidentDescription: newRequest.incidentDescription,
    assignedToVendorId: newRequest.vendor,
    ticketName: newRequest.ticketName,
    symptoms: newRequest.symptoms,
    troubleshootingGuideAttempt: newRequest.troubleshootingGuideAttempt,
    selfServiceTroubleshootingStatus: newRequest.troubleshootingStatus?.label,
  };
}

/**
 * Create a self-service request
 * @param {object} newRequest
 * @returns {object}
 */
export async function saveNewSelfServiceRequest(newRequest) {
  try {
    const payload =
      newRequest.requestType === 'Self Service Preventative'
        ? newRequest
        : toCreateRequestDto(newRequest);
    const res = await esApiV3.post(api.saveSelfServiceRequest(), payload);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to save new self-service request',
    );
  }
  return null;
}

/**
 * Get a list of self-service vendors
 * @returns {Array}
 */
export async function getSelfServiceVendors() {
  try {
    const res = await esApiV3.get(api.allSelfServiceVendors());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get self-service vendors',
    );
  }
  return null;
}

/**
 * Update a request
 * @returns {object}
 * @param id
 * @param requestUpdate
 */
export async function updateSSORequest(id, requestUpdate) {
  try {
    const res = await esApiV3.patch(
      api.updateSelfServiceRequest(id),
      requestUpdate,
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update request',
    );
  }
  return null;
}

/**
 * Complete a request
 * @param {string} id
 * @param {object} form
 * @returns {object}
 */
export async function completeSSORequest(id, completeFormData) {
  const payload = {
    requestStatus: 'Request Closed',
    completedAt: completeFormData.resolutionDate,
    resolutionStatement: completeFormData.resolutionStatement,
  };
  try {
    const res = await esApiV3.patch(api.updateSelfServiceRequest(id), payload);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to complete request',
    );
  }
  return null;
}

/**
 * Complete a request
 * @param {string} id
 * @param {Array} assets
 * @returns {object}
 */
export async function updateSelfServiceRequestPMAssets(id, assets) {
  const payload = {
    pmAssets: assets,
    pmLocations: assets.map((asset) => ({ id: asset.locationId })),
  };
  try {
    const res = await esApiV3.patch(api.updateSelfServiceRequest(id), payload);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update PM request asset',
    );
  }
  return null;
}

/**
 * Generate the next PM self-service request from the previous one
 * @param {string} previousRequestId;

 * @returns {object}
 */
export async function generateNextPmService(previousRequestId) {
  try {
    const res = await esApiV3.post(
      api.generateNextPMSelfServiceRequest(previousRequestId),
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to generate the next PM service request',
    );
  }
  return null;
}

/**
 * Get a list of previous PM self-service requests
 * @param {string} threadId
 * @returns {Promise<Array>}
 */
export async function getSelfServicePmRequestHistory(threadId) {
  try {
    const res = await esApiV3.get(api.getSelfServicePmRequestHistory(threadId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get self-service PM request history',
    );
    return [];
  }
}

/**
 * Post an activity
 * @param {string}
 * @returns {object}
 */
export async function addActivity(body) {
  try {
    const res = await esApiV3.post(api.selfServiceActivity(), body);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to add activity',
    );
  }
  return null;
}

/**
 * Patch an activity
 * @param {string}
 * @returns {object}
 */
export async function updateActivity(id, description) {
  try {
    const res = await esApiV3.patch(api.selfServiceActivityById(id), {
      description,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to update activity',
    );
  }
  return null;
}

/**
 * Delete an activity
 * @param {string}
 * @returns {object}
 */
export async function deleteActivity(id) {
  try {
    const res = await esApiV3.delete(api.selfServiceActivityById(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to delete activity',
    );
  }
  return null;
}

/**
 * Delete an attachment
 * @param {string}
 * @param {File}
 * @returns {object}
 */
export async function deleteAttachment(
  requestId,
  attachmentId,
  attachmentType,
) {
  try {
    const res = await esApiV3.delete(
      api.selfServiceAttachmentById(requestId, attachmentId),
      {
        params: { attachmentType },
      },
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to delete attachment',
    );
  }
  return null;
}

/**
 * Get attachments by request id
 * @param {string}
 * @param {File}
 * @returns {object}
 */
export async function getAttachments(requestId) {
  try {
    const res = await esApiV3.get(api.selfServiceAttachments(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get attachments',
    );
  }
  return null;
}

/**
 * Get prompts by request id
 * @param {string}
 * @returns {object}
 */
export async function getRequestPrompts(requestId) {
  try {
    const res = await esApiV3.get(api.selfServiceRequestPrompts(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get prompts',
    );
  }
  return null;
}

/**
 * Get automations by request id
 * @param {string}
 * @returns {object}
 */

export async function getRequestAutomations(requestId) {
  try {
    const res = await esApiV3.get(api.selfServiceRequestAutomations(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get automations',
    );
  }
  return null;
}

/**
 * Create follow up by request id
 * @param {string}
 * @param {object}
 * @returns {object}
 */
export async function createFollowUp(requestId, body) {
  try {
    const res = await esApiV3.post(
      api.selfServiceRequestFollowUp(requestId),
      body,
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to create follow up',
    );
  }
  return null;
}

/**
 * Get follow ups by request id
 * @param {string}
 * @returns {object}
 */
export async function getFollowUps(requestId) {
  try {
    const res = await esApiV3.get(api.selfServiceRequestFollowUp(requestId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get follow ups',
    );
  }
  return null;
}

/**
 * Get guides by assetId
 * @param {string}
 * @returns {object}
 */
export async function getGuidesForAssetId(assetId) {
  try {
    const res = await esApiV3.get(
      api.selfServiceTroubleshootingGuidesByAsset(assetId),
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get troubleshooting guides by asset',
    );
  }
  return null;
}

/**
 * Get guides by id
 * @param {string}
 * @returns {object}
 */
export async function getGuideById(id, query) {
  try {
    const res = await esApiV3.get(
      api.selfServiceTroubleshootingGuidesById(id),
      { params: query },
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get troubleshooting guides by id',
    );
  }
  return null;
}

/**
 * Create troubleshooting attempt
 * @param {object}
 * @returns {object}
 */
export async function createTroubleshootingAttempt(body) {
  try {
    const res = await esApiV3.post(
      api.selfServiceTroubleshootingAttempt(),
      body,
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to create troubleshooting attempt',
    );
  }
  return null;
}

/**
 * Get troubleshooting attempts
 * @param {object}
 * @returns {object}
 */
export async function getTroubleshootingAttempts(query) {
  try {
    const res = await esApiV3.get(api.selfServiceTroubleshootingAttempt(), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get troubleshooting attempts',
    );
  }
  return null;
}

/**
 * Get troubleshooting attempt by Id
 * @param {object}
 * @returns {object}
 */
export async function getTroubleshootingAttemptById(id, query) {
  try {
    const res = await esApiV3.get(
      api.selfServiceTroubleshootingAttemptById(id),
      {
        params: query,
      },
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get troubleshooting attempt by id',
    );
  }
  return null;
}

/**
 * Assign a vendor to a request
 * @param {string} requestId
 * @param {string}  vendorId
 * @returns {object}
 */
export async function assignVendor(requestId, vendorId) {
  try {
    const res = await esApiV3.post(api.assignVendor(requestId), {
      assignedToVendorId: vendorId,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to assign vendor',
    );
  }
  return null;
}

/**
 * Set ETA
 * @param {string} requestId
 * @param {object} eta
 * @param {string | null} eta.vendorEtaStartDate
 * @param {string | null} eta.vendorEtaEndDate
 * @param {string | null} eta.vendorEtaStartTime
 * @param {string | null} eta.vendorEtaEndTime
 * @returns {object}
 */
export async function setEta(requestId, eta) {
  try {
    const res = await esApiV3.post(api.setEta(requestId), eta);
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex, 'An error occurred while trying to set ETA');
  }
  return null;
}

/**
 * Get pm checklist items by requestId
 * @param {string}
 * @returns {object}
 */
export async function getPmChecklistItemsForRequest(requestId, relations) {
  try {
    const res = await esApiV3.get(api.selfServicePmChecklistItems(), {
      params: { requestId, relations },
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get pm checklist items by request id',
    );
  }
  return null;
}

/**
 * Mark pm checklist item
 * @param {
 * checklistItemId: string,
 * status: 'Done' | 'Not Done'}
 * @returns {object}
 */
export async function markPmChecklistItem(body) {
  try {
    const res = await esApiV3.post(api.markServicePmChecklistItem(), body);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to mark pm checklist item',
    );
  }
  return null;
}

/**
 *
 * @param body:
 * {
 * created: Array<{PMListItems>} | null,
 * updated: Array<{PMListItems>} | null,
 * deleted: Array<{PMListItemsIds>} | null,
 * }
 * @returns {object}
 */

export async function bulkCrudPmChecklistItem(body) {
  try {
    const res = await esApiV3.post(api.bulkCrudServicePmChecklistItems(), body);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to bulk edit pm checklist items',
    );
  }
  return null;
}

export async function createVendorContractedRate(body) {
  try {
    const res = await esApiV3.post(api.vendorContractedRate(), body);
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to create vendor contracted rate',
    );
  }
  return null;
}

export async function deleteVendorContractedRate(id) {
  try {
    const res = await esApiV3.delete(api.vendorContractedRateById(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to delete vendor contracted rate',
    );
  }
  return null;
}

export async function getVendorContractedRates(vendorId) {
  try {
    const res = await esApiV3.get(api.vendorContractedRatesByVendor(vendorId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get vendor contracted rates',
    );
  }
  return null;
}
